.eventincidents_wrapper {
  .ds_button {
    width: 100%;
    padding: 0 0px;
    margin: 0;
    margin-top: 0px;
  }

  .eventincidents_search {
    justify-content: center;
    align-items: end;
  }
}
