// Header no navigation
.top-menu-bar {
  width: 100%;
  height: 58px;
  padding: 7px;
  color: #ffffff;
  background-color: #0065bd;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.footer-top-menu-bar {
  width: 100%;
  min-height: 58px;
  padding: 7px;
  color: #ffffff;
  background-color: #0065bd;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.responsive_list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-left: 0;
}

@media (max-width: 1299px) {
  .top-menu-bar {
    justify-content: flex-end;

    #contextualNavigation {
      display: none;
    }
  }
}

.top-menu {
  width: 100%;
}

.active {
  background-color: #ffffff !important;
  color: #00437e;
}

.notification_header_icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 25px;
  }
  .notification_icon {
    cursor: pointer;
    padding: 1.2rem;
  }
}

.bg-danger {
  bottom: 28px;
  position: absolute;
  left: 26px;
}

// Home page login
.login_home_page {
  justify-content: flex-end !important;
  li {
    padding-right: 25px !important;
  }
}
