@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "@scottish-government/pattern-library/src/base/all-base";
@import "@scottish-government/pattern-library/src/forms/all-forms";
@import "@scottish-government/pattern-library/src/components/all-components";

@import "./spinner";
@import "./headers";
@import "./eventincidents";
@import "./notifications";
@import "./notifsidebar";
@import "./classification";
@import "./contextualNavbar";
@import "./button";

// Brand
$ds_colour__brand: #0065bd;
$ds_colour__brand--secondary: #002d54;

// Text
$ds_colour__text: #333333;
$ds_colour__text--secondary: #727272;

// Links
$ds_colour__link: #0065bd;
$ds_colour__link--table: #212529;
$ds_colour__link--hover: #00437e;
$ds_colour__link--hover__background: #d9effc;
$ds_colour__link--current: #333333;
$ds_colour__link--current__background: #f8f8f8;

// Focus
$ds_colour__link--focus: #333333;
$ds_colour__link--focus__background: #fdd522;

// Positive and negative messaging
$ds_colour__state--negative: #d32205;
$ds_colour__state--positive: #428542;

// Borders
$ds_colour__border: #b3b3b3;
$ds_colour__border--dark: #333333;
$ds_colour__border--light: #f8f8f8;
$ds_colour__border--highlight: #0065bd;

body,
html {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  color: $ds_colour__link--current;
  height: 100%;
}

.app_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: 100vh;
}
.ds_content_wrapper {
  min-height: 743px;
}

.ds_wrapper {
  flex: 1 1 auto;
}

.flex-flex-direction {
  display: flex;
  flex-direction: column;
}

.publicLogo {
  cursor: pointer;
  width: 100%;
}

.regionAreaFooter {
  width: 100%;
  margin: 20px 0;
  background: #f8f8f8;
  border: 1px solid #07437e;
  height: 100%;
}

.regionBodyFooter {
  padding: 6px 14px;
}

.staticHeading {
  padding: 8px;
  margin-bottom: 0;
}

.footerLoginLink {
  display: flex;
  height: 40px;
  width: 132px;
  align-items: center;
  font-weight: 400;
}

// .ds_content_sidebar {
//   min-height: 66vh;
// }

h1 {
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: 700 !important;
}
h5 {
  font-weight: bold !important;
}

.helpAdviceHeading {
  margin-top: 0 !important;
  font-size: 1.25rem;
}

// Content Wrapper
.page_wrapper {
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 2rem;
}

// warning Text
.ds_warning-text__text {
  padding-left: 60px;
  font-size: 19px;
}

.ds_sideba_logo {
  padding-bottom: 40px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 50%;
  }
}

// Right Large Container
.right_large_doc {
  border-left: 2px solid $ds_colour__link;
  // padding-left: 30px !important;
  //padding-right: 40px !important;
  // padding-top: 0px;
  // padding-bottom: 40px;
  // width: 100%;
  height: 100%;
}

.customRow {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  height: 100%;
}

.remove {
  visibility: hidden;
}

// DropDown Menu  -  Down Arrow

.ds_select-wrapper {
  margin-bottom: 0px;
  min-height: 32px !important;

  .ds_select {
    height: 32px;
    background-color: white;
    padding: 0 0 0 14px;
    min-height: 32px;
  }
  .ds_select-arrow,
  .ds_select-calendar,
  .ds_select-search {
    width: 32px;
    height: 32px;
  }
  .ds_select-arrow::before {
    border-width: 0px 0px 3px 3px !important;
    height: 10px !important;
    width: 10px;
    right: 11px;
    top: 8px;
  }
  .ds_select-calendar {
    background: #0065bd;
    bottom: 0;
    content: "";
    outline: 2px solid transparent;
    outline-offset: -2px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .ds_select-calendar::before {
    height: 28px !important;
    width: 28px;
    left: 6px;
    bottom: 0;
    color: #ffffff;
    position: absolute;
    z-index: 1;
    content: "";
    background: url("../assets/img/calendar.svg") no-repeat;
    background-size: 20px;
    background-color: transparent;
    border-width: 0px 0px 3px 3px;
    display: inline-block;
  }

  .ds_select-search {
    height: 32px;
    width: 32px;
    position: absolute;
    border: none;
    background: #0065bd;
    right: 0;
    top: 0;
  }
}

.ds_loading {
  position: relative;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.6;
  img {
    width: 40px;
  }
}

// anchor for search icon
.ds_searchIcon {
  height: 26px;
  width: 20px;
}

// Input Height
.ds_input {
  height: 32px;
  min-height: 32px;
  background: white;
}

.ds_input__wrapper {
  .ds_input {
    margin-right: 0;
    padding-right: 0;
  }
}

.upload_file_wrapper {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-end !important;
}

.upload_select_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .ds_input {
    margin-bottom: 0;
  }
}

.upload_select_wrapper > ol {
  columns: 2;
  width: 500px;
}

.upload_select_wrapper > label {
  font-weight: bold;
  font-size: 14px;
}

.upload_file {
  display: flex;
  justify-content: space-between;
}

.upload_button {
  border: none;
  background-color: #0065bd;
  padding: 4px 1rem;
  color: white;
  cursor: pointer !important;

  &:hover {
    background-color: #07437e;
  }
}

.validationContainer {
  margin-bottom: 1rem;
}

.validation {
  margin-bottom: 1rem;

  span {
    color: $ds_colour__state--negative;
    font-weight: bold;
  }
}

.ds_select-upload-wrapper {
  border-radius: 0;
  display: block;
  padding: 0;
  position: relative;
}

.ds_select-upload-wrapper > .ds_select {
  min-height: 0px !important;
  padding: 8px 3rem 8px 1rem !important;
  background-color: white !important;
}

.ds_select-upload-wrapper > .ds_select-arrow {
  background: #0065bd;
  bottom: 0;
  content: "";
  outline: 2px solid transparent;
  outline-offset: -2px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
}

.ds_select-upload-wrapper > .ds_select-arrow::before {
  bottom: 0;
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  content: "";
  background-color: transparent;
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 3px;
  border-left-width: 3px;
  border-top-color: transparent;
  display: inline-block;
  height: 12px;
  width: 12px;
  transform: rotate(-45deg);
  right: 12px;
  top: 9px;
}

.ds_select-upload-wrapper > .ds_select-arrow-icon {
  background-color: #0065bd;
  position: absolute;
  height: 100%;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.ds_select:focus + .ds_select-arrow-icon {
  background-color: #fdd522 !important;
}

.ds_select-wrapper > .ds_select-arrow-icon {
  background-color: #0065bd;
  position: absolute;
  height: 100%;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.ov_label {
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 4px !important;
}

// Overview page input
.ov_input {
  height: 32px;
  border: 2px solid currentColor;
  border-radius: 0;
  display: block;
  margin-bottom: 12px;
  background: white;
  padding: 8px;
  width: 75%;
}

table {
  border-color: #07437e;
}

.ov_recentactivity_table {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  thead > tr > th {
    font-weight: 700;
  }

  thead > tr > th:first-child {
    padding-left: 1rem;
    cursor: pointer;
  }

  thead > tr > th:nth-child(2),
  thead > tr > th:nth-child(3),
  thead > tr > th:nth-child(4) {
    cursor: pointer;
  }

  thead > tr > th {
    width: 10%;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  th:first-child,
  td:first-child {
    width: 25%;
  }

  th:nth-child(4) {
    width: 20%;
  }

  td:first-child {
    position: relative;
    padding-left: 1rem;
  }

  tbody > tr > td:first-child:before {
    content: "";
    position: absolute;
    top: 0px;
    width: 4px;
    background-color: #d9effc;
    height: 100%;
    left: 0;
  }

  tbody > tr:hover > td:first-child::before {
    background-color: #07437e;
  }

  thead > tr > *,
  tbody > tr > td > * {
    padding: 8px 16px 8px 8px;
  }

  .tableLink {
    color: $ds_colour__link--table;
    display: block;
    text-decoration: none;
  }

  .tableLink:hover {
    background-color: transparent;
    color: $ds_colour__link--table;
    outline: 0;
    transition-duration: 0;
  }

  .tableLink:focus {
    outline: 0;
    outline-offset: 0;
    background-color: transparent;
    box-shadow: none;
    color: $ds_colour__link--table;
    text-decoration: none;
    transition-duration: 0s;
  }

  tr {
    border-left: 0.1px solid #07437e;
    border-right: 0.1px solid #07437e;
    border-bottom: 0.1px solid #07437e;
  }

  tbody > tr:hover {
    cursor: pointer;
  }

  tbody > tr:hover td {
    background-color: #d9effc;
  }

  thead > tr:first-child {
    border-top: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  tbody > tr:nth-child(even):hover > td {
    background-color: #d9effc;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.user_management_table {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  thead > tr > th {
    font-weight: 700;
  }

  thead > tr > th:first-child {
    padding-left: 1rem;
  }

  thead > tr > th:nth-child(2) {
    cursor: pointer;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  td:first-child {
    // position: relative;
    padding-left: 1rem;
  }

  tbody > tr > td:first-child:before {
    content: "";
    position: absolute;
    top: 0px;
    width: 4px;
    background-color: #d9effc;
    height: 100%;
    left: 0;
  }

  tbody > tr > td:first-child:hover::before {
    background-color: #07437e;
  }

  tr > * {
    padding: 8px 16px 8px 8px;
  }

  tr {
    border-left: 0.1px solid #07437e;
    border-right: 0.1px solid #07437e;
    border-bottom: 0.1px solid #07437e;
  }

  tbody > tr:hover {
    cursor: pointer;
  }

  tbody > tr:hover td {
    background-color: #d9effc;
  }

  thead > tr:first-child {
    border-top: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
    word-break: break-all;
  }

  tbody > tr > td:nth-child(2) {
    word-break: break-word;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  tbody > tr:nth-child(even):hover > td {
    background-color: #d9effc;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.audit_management_table {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  thead > tr > th {
    font-weight: 700;
  }

  thead > tr > th:first-child {
    padding-left: 1rem;
    cursor: pointer;
  }

  thead > tr > th:nth-child(2),
  thead > tr > th:nth-child(3) {
    cursor: pointer;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  td:first-child {
    position: relative;
    padding-left: 1rem;
  }

  tbody > tr > td:first-child:before {
    content: "";
    position: absolute;
    top: 0px;
    width: 4px;
    background-color: #d9effc;
    height: 100%;
    left: 0;
  }

  tr > * {
    padding: 8px 16px 8px 8px;
  }

  tr {
    border-left: 0.1px solid #07437e;
    border-right: 0.1px solid #07437e;
    border-bottom: 0.1px solid #07437e;
  }

  tbody > tr:hover td {
    background-color: #d9effc;
  }

  thead > tr:first-child {
    border-top: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr > td:nth-child(1),
  tbody > tr > td:nth-child(3) {
    word-break: break-all;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  tbody > tr:nth-child(even):hover > td {
    background-color: #d9effc;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.assetSearchTable {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  thead > tr > th {
    font-weight: 700;
  }

  thead > tr > th:first-child {
    padding-left: 1rem;
    cursor: pointer;
  }

  thead > tr > th:nth-child(2),
  thead > tr > th:nth-child(3),
  thead > tr > th:nth-child(4) {
    cursor: pointer;
  }

  thead > tr > th {
    width: 40%;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  th:first-child,
  td:first-child,
  th:last-child,
  td:last-child {
    width: 10%;
  }

  td:first-child {
    position: relative;
    padding-left: 1rem;
  }

  tbody > tr > td:first-child:before {
    content: "";
    position: absolute;
    top: 0px;
    width: 4px;
    background-color: #d9effc;
    height: 100%;
    left: 0;
  }

  tbody > tr:hover > td:first-child::before {
    background-color: #07437e;
  }

  thead > tr > *,
  tbody > tr > td > * {
    padding: 8px 16px 8px 8px;
  }

  .tableLink {
    color: $ds_colour__link--table;
    display: block;
    text-decoration: none;
  }

  .tableLink:hover {
    background-color: transparent;
    color: $ds_colour__link--table;
    outline: 0;
    transition-duration: 0;
  }

  .tableLink:focus {
    outline: 0;
    outline-offset: 0;
    background-color: transparent;
    box-shadow: none;
    color: $ds_colour__link--table;
    text-decoration: none;
    transition-duration: 0s;
  }

  tr {
    border-left: 0.1px solid #07437e;
    border-right: 0.1px solid #07437e;
    border-bottom: 0.1px solid #07437e;
  }

  tbody > tr:hover {
    cursor: pointer;
  }

  tbody > tr:hover td {
    background-color: #d9effc;
  }

  thead > tr:first-child {
    border-top: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  tbody > tr:nth-child(even):hover > td {
    background-color: #d9effc;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

// Sidebar
.ds_side-navigation__link {
  color: #333333 !important;
  padding-left: 2.5rem;
}

.ds_side-navigation__link:hover::before,
.ds_side-navigation__link:focus::before,
.ds_current::before,
.ds_current:focus::before {
  width: 4px !important;
}

.ds_side-navigation__link:focus,
.ds_side-navigation__link:active {
  box-shadow: 0 0px !important;
  background-color: #f8f8f8 !important;

  .indent::before {
    content: "";
    width: 4px;
    background: #0065bd;
    position: absolute;
    top: 10px;
    bottom: 0;
    height: 22px;
    left: 20px;
  }
}

.ds_side-navigation__link.ds_current:not(:focus)::before {
  content: "";
  width: 4px;
  background: #0065bd;
  position: absolute;
  top: 10px;
  bottom: 0;
  height: 22px;
  left: 20px;
}

// DS question
.ds_question {
  margin-bottom: 0;
}

.ds_question__error-message {
  margin-top: 15px;
}

.regionHeader {
  width: 100%;
  padding: 8px;
  color: #ffffff;
  background-color: #07437e;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-start;
  border: 1px solid #07437e;

  h1,
  h2 {
    font-size: 14px;
    font-weight: normal !important;
    line-height: var(--bs-body-line-height);
    margin: 0;
    padding: 0;
  }
}

.regionArea {
  width: 100%;
  margin: 20px 0;
  background: $ds_colour__border--light;
}

.regionContainer {
  max-height: 191px;
  min-height: 191px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
  border: 1px solid #07437e;
}

.communicationTemplate {
  h2 {
    font-size: 1.25rem;
    font-weight: bold;
  }
}

.tableContainer {
  border: 1px solid #07437e;
}

.regionWrapper {
  width: 100%;
  margin: 20px 0;
}

.regionBody {
  border: 1px solid #07437e;
  padding: 6px 14px;
}

.regionBody > p:last-child {
  margin-bottom: 0 !important;
}

.regioncomponent {
  width: 100%;
  background: $ds_colour__border--light;
  margin-bottom: 1.5rem;
}

.regionPaginate {
  .navigationButtons {
    margin: 1rem auto 2rem auto;
  }
}

.filter-elements {
  margin: 6px 14px;
}

.regionTable tbody tr td:first-child {
  font-weight: bold;
  width: 20%;
  vertical-align: baseline;
}

.regionTable tr td:nth-child(2) {
  padding-left: 2rem;
  vertical-align: baseline;
}

.regionTable tr {
  height: 2rem;
}

//paginate style
.navigationButtons {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 3rem auto 0 auto;
  padding: 0;
}

.navigationButtons li {
  margin-bottom: 0;
  line-height: 2rem;
}

.navigationButtons a {
  padding: 1rem;
  border: 1px solid #808080;
  cursor: pointer;
  color: #07437e;
  text-decoration: none;
  background-color: white;
}

.navigationButtons a:hover {
  color: #07437e;
}

.navigationButtons a[aria-disabled="true"] {
  color: gray;
}

.navigationActive a {
  background-color: #07437e;
  color: white !important;
}

.navigationDisabled a {
  color: grey;
}

.navigationDisabled a:hover {
  color: grey;
}

// Notifications
.notification_item_list_inner > ul {
  list-style: none;
  padding: 0;
  font-weight: 500;
  margin: 0;
}

.notification_item_list_inner > ul > li > a {
  color: #0065bd;
}

.notifHeader {
  padding-left: 14px;
}

.notifications_list:hover {
  background-color: #d9effc;
  border-left: 5px solid #07437e !important;
  cursor: pointer;
}

.notifsidebar__wrapper {
  padding-left: 4px !important;
}

.notifsidebar__wrapper .notifications:first-child {
  margin-top: 5px !important;
}

.notificationLink {
  text-decoration: none;
}

.home {
  list-style: none;
  margin: 0 0 1rem 0;
  padding-left: 0;
  font-size: 1.1rem;

  a {
    color: #07437e;
  }
}

.loginWrapper {
  margin: 2rem 0 1rem 0;

  h1 {
    margin: 1rem 0 2rem 0;
    padding: 0;
    font-size: 1.25rem;
  }
}

.treatmentProcess {
  margin: 1rem 0;
  padding: 0;
  list-style: none;
  column-count: 4;
  column-gap: 0%;

  li {
    display: flex;
    justify-content: space-between;
    height: 46px;
    background-color: white;
    align-items: center;
    margin: 0;
    border: 1px solid black;
    padding: 0 1.5rem;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.treatmentProcessAnchor {
  margin-bottom: 1rem;
}

.assetComponentContainer {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.assetHeader {
  width: 100%;
  padding: 8px;
  color: #ffffff;
  background-color: #07437e;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid #07437e;

  h1,
  h2 {
    font-size: 14px;
    font-weight: normal !important;
    line-height: var(--bs-body-line-height);
    margin: 0;
    padding: 0;
  }
}

.associatedEI {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  th:hover {
    cursor: pointer;
  }

  thead > tr > th {
    font-weight: 700;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  td:first-child {
    position: relative;
  }

  tbody > tr > td:first-child:hover::before {
    background-color: #07437e;
  }

  tr > * {
    padding: 8px 16px 8px 8px;
  }

  tr {
    border-bottom: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.sampleFailures {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  thead > tr > th {
    font-weight: 700;
  }

  thead > tr > th:nth-child(3) > div > select {
    max-width: 179px;
    width: 100%;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  td:first-child {
    position: relative;
  }

  tbody > tr > td:first-child:hover::before {
    background-color: #07437e;
  }

  tr > * {
    padding: 8px 16px 8px 8px;
  }

  tr {
    border-bottom: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.stackHeadingElement {
  display: flex;
  flex-direction: column;
}

.validationTable {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  thead > tr > th {
    font-weight: 700;
  }

  thead > tr > th:first-child {
    padding-left: 1rem;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  th:first-child,
  td:first-child {
    width: 25%;
  }

  td:first-child {
    position: relative;
    padding-left: 1rem;
  }

  tbody > tr > td:first-child:before {
    content: "";
    position: absolute;
    top: 0px;
    width: 4px;
    background-color: #d9effc;
    height: 100%;
    left: 0;
  }

  tr > * {
    padding: 8px 16px 8px 8px;
  }

  tr {
    border-left: 0.1px solid #07437e;
    border-right: 0.1px solid #07437e;
    border-bottom: 0.1px solid #07437e;
  }

  thead > tr:first-child {
    border-top: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.samplingRequirements {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  thead > tr > th:nth-child(2) > div > select {
    max-width: 164px;
  }

  th:nth-child(3):hover,
  th:nth-child(4):hover {
    cursor: pointer;
  }

  thead > tr > th {
    font-weight: 700;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  td:first-child {
    position: relative;
  }

  tbody > tr > td:first-child:hover::before {
    background-color: #07437e;
  }

  tr > * {
    padding: 8px 16px 8px 8px;
  }

  tr {
    border-bottom: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.assetTreeView {
  margin-left: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.assetTreeView {
  ul {
    padding: 0;
    margin: 0;
    width: 20%;
  }
  li {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #333333;
    align-items: start;

    div:nth-child(2) {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .customToolTip {
    position: relative;
    display: block;
  }

  .customToolTip .customToolTipText {
    visibility: hidden;
    background-color: #07437e;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 0px;
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    bottom: -1px;
    left: 0px;
  }

  .customToolTip:hover .customToolTipText {
    visibility: visible;
  }

  .yellow {
    background: rgba(252, 213, 51, 0.5);
  }

  .green {
    background: rgba(66, 133, 66, 0.5);
  }

  .purple {
    background: rgba(146, 54, 136, 0.5);
  }

  .peach {
    background: rgba(240, 119, 55, 0.5);
  }

  .turquoise {
    background: rgba(69, 161, 151, 0.5);
  }

  .blue {
    background: rgba(134, 160, 239);
  }
}

.assetTreeView span {
  font-size: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 3rem;
  border: 1px solid #333333;
}

.key > ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.key > ul:last-child,
.key > ul > li {
  margin-bottom: 0;
  font-size: 12px;
  margin-right: 1rem;
}

.square {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 0.3rem;
}

.yellow {
  background: rgba(252, 213, 51, 0.5);
}

.green {
  background: rgba(66, 133, 66, 0.5);
}

.purple {
  background: rgba(146, 54, 136, 0.5);
}

.peach {
  background: rgba(240, 119, 55, 0.5);
}

.turquoise {
  background: rgba(69, 161, 151, 0.5);
}

.blue {
  background: rgba(134, 160, 239);
}

.sampleData {
  width: 100%;
  margin-bottom: 0;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  th:hover {
    cursor: pointer;
  }

  th:nth-child(4):hover,
  th:nth-child(5):hover,
  th:nth-child(6):hover,
  th:nth-child(7):hover {
    cursor: auto;
  }

  th:nth-child(4) > select:hover,
  th:nth-child(6) > select:hover {
    cursor: pointer;
  }

  thead > tr > th {
    font-weight: 700;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  thead > tr > th:nth-child(1),
  thead > tr > th:nth-child(4) {
    width: 20%;
  }

  thead > tr > th:nth-child(2) {
    width: 25%;
  }

  thead > tr > th:nth-child(3) {
    width: 15%;
  }

  thead > tr > th:nth-child(5),
  thead > tr > th:nth-child(6) {
    width: 10%;
  }

  td:first-child {
    position: relative;
  }

  tbody > tr > td:first-child:hover::before {
    background-color: #07437e;
  }

  tr > * {
    padding: 8px 16px 8px 8px;
  }

  tr {
    border-bottom: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  tbody > tr:last-child {
    border-bottom: 0;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.timeoutContainer {
  position: fixed;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 501;

  .regionArea {
    width: 500px;
  }

  .regionBody {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    color: white;
    background-color: #0065bd;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 2px;
  }
}

.assessmentSaveContainer {
  position: fixed;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 501;

  .regionArea {
    width: 500px;
  }

  .regionBody {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.eventInformation {
  .ds_label {
    margin-bottom: 0px;
  }
}

.hazard {
  margin: 1rem 0;
}

.hazard_container {
  display: flex;
  align-items: center;
}

.hazard_button {
  color: #0065bd;
  border: none;
  border-bottom: 1px solid #0065bd;
  background: none;
  margin: 0.5rem 1rem 0.5rem 0;
  font-size: 13px;
}

.hazard_delete {
  margin: 0 0.5rem 0 1rem;
  cursor: pointer;
}

.root_cause {
  margin: 0.5rem 0 1rem 0;
}

.additionalInfo_label {
  padding-top: 0.7rem;
}

.additionalInfo_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.save {
  cursor: pointer;
}

.modalButtons {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
}

.assessmentSubHeading {
  font-size: 1.25rem;
}

.assessmentLinks {
  margin: 1rem 0;
}

// Change padding of checkboxes and radios from ddl
.ds_checkbox__label,
.ds_radio__label {
  padding: 9px 32px 4px 16px;
}

.uploadContainer {
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background-color: #0065bd;
    padding: 6px 1.5rem;
    color: white;
    cursor: pointer;
  }

  button:disabled {
    cursor: initial;
    background-color: white;
    color: grey;
    border: 1px solid #808080;
  }
}

.fileUploadTable {
  border: 1px solid #07437e;
  width: 100%;
  border-top: none;

  thead {
    th {
      padding: 4px 1rem;
      border-left: 1px solid #07437e;
      border-bottom: 1px solid #07437e;
    }

    th:first-child {
      border-left: none;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: #fff;
    }

    td:first-child {
      word-break: break-all;
    }

    td {
      padding: 4px 1rem;
      border-bottom: 1px solid #07437e;
    }

    td:last-child {
      text-align: center;
      padding: 0px;
      cursor: pointer;
    }
  }
}

.helpAdviceTable {
  tr:nth-child(even) > td {
    padding-bottom: 1rem;
  }

  tr:last-child > td {
    padding-bottom: 0;
  }
}

.confirmContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;

  p {
    margin: 0;
  }
}

.recent_activity_table {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  thead > tr > th {
    font-weight: 700;
  }

  // thead > tr > th {
  //   width: 10%;
  // }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  td:first-child {
    position: relative;
    padding-left: 1rem;
  }

  td:last-child {
    word-break: break-word;
    width: 20%;
  }

  tbody > tr > td:first-child:before {
    content: "";
    position: absolute;
    top: 0px;
    width: 4px;
    background-color: #d9effc;
    height: 100%;
    left: 0;
  }

  tbody > tr > td:first-child:hover::before {
    background-color: #07437e;
  }

  tr > * {
    padding: 8px 16px 8px 8px;
  }

  tr {
    border-left: 0.1px solid #07437e;
    border-right: 0.1px solid #07437e;
    border-bottom: 0.1px solid #07437e;
  }

  tbody > tr:hover td {
    background-color: #d9effc;
  }

  thead > tr:first-child {
    border-top: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  tbody > tr:nth-child(even):hover > td {
    background-color: #d9effc;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.la_codes_table {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  thead > tr > th {
    font-weight: 700;
  }

  thead > tr > th {
    width: 10%;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  td:first-child {
    position: relative;
    padding-left: 1rem;
  }

  tbody > tr > td:first-child:before {
    content: "";
    position: absolute;
    top: 0px;
    width: 4px;
    background-color: #d9effc;
    height: 100%;
    left: 0;
  }

  tbody > tr > td:first-child:hover::before {
    background-color: #07437e;
  }

  tr > * {
    padding: 8px 16px 8px 8px;
  }

  tr {
    border-left: 0.1px solid #07437e;
    border-right: 0.1px solid #07437e;
    border-bottom: 0.1px solid #07437e;
  }

  tbody {
    tr {
      td:first-child {
        width: 70%;
      }
      td {
        width: 30%;
      }
    }
  }

  tbody > tr:hover td {
    background-color: #d9effc;
  }

  thead > tr:first-child {
    border-top: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  tbody > tr:nth-child(even):hover > td {
    background-color: #d9effc;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.la_parameter_codes {
  width: 100%;

  th {
    background-color: $table-header-background-colour;
    font-weight: $bold;
    position: relative;
    text-align: left;
  }

  thead > tr > th {
    font-weight: 700;
  }

  thead > tr > th {
    width: 10%;
  }

  th,
  td {
    vertical-align: top;
  }

  th[colspan] {
    border: 1px solid $table-border-colour;
  }

  th:not(:first-child)::before {
    border-left: 1px solid #07437e;
    bottom: 0px;
    content: "";
    left: -1px;
    position: absolute;
    top: 0px;
  }

  td:first-child {
    position: relative;
    padding-left: 1rem;
  }

  tbody > tr > td:first-child:before {
    content: "";
    position: absolute;
    top: 0px;
    width: 4px;
    background-color: #d9effc;
    height: 100%;
    left: 0;
  }

  tbody > tr > td:first-child:hover::before {
    background-color: #07437e;
  }

  tr > * {
    padding: 8px 16px 8px 8px;
  }

  tr {
    border-left: 0.1px solid #07437e;
    border-right: 0.1px solid #07437e;
    border-bottom: 0.1px solid #07437e;
  }

  tbody {
    tr {
      td:first-child,
      td:nth-child(3),
      td:nth-child(4) {
        width: 10%;
      }
    }
  }

  tbody > tr:hover td {
    background-color: #d9effc;
  }

  thead > tr:first-child {
    border-top: 0.1px solid #07437e;
  }

  tbody > tr > td {
    background-color: $ds_colour__white;
    font-weight: 400;
  }

  tbody > tr:nth-child(even) > td {
    background-color: $ds_colour__grey--lighter;
  }

  tbody > tr:nth-child(even):hover > td {
    background-color: #d9effc;
  }

  caption {
    font-size: 16px;
    font-weight: $bold;
    line-height: 21px;
    margin-bottom: 14px;
    text-align: left;

    @include ds_media-query(medium) {
      font-size: 19px;
      line-height: 28px;
    }
  }
}

.logBody {
  border: 1px solid #07437e;

  .logSection {
    border-top: 1px solid #07437e;
    padding: 1rem;
  }

  .logSubSection {
    margin-bottom: 1rem;
  }
}

.login_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 3rem 0;
}

.login_buttons_container {
  display: flex;
  justify-content: space-between;
}

.login_buttons_forgot_container {
  display: flex;
  justify-content: center;
}

.logo_footer_container {
  display: flex;
  align-items: center;
  background: white;

  a {
    padding: 1rem;
  }
}

@media (min-width: 1300px) {
  .ds_wrapper {
    width: 1300px !important;
  }
}

.userFields {
  margin-bottom: 1rem;
}
