.button_container {
  display: flex;
  justify-content: flex-end;

  button {
    border: none;
    background-color: #0065bd;
    padding: 6px 1.5rem;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 67, 126, 1);
    }
  }
}

.multiple_button_container {
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background-color: #0065bd;
    padding: 6px 1.5rem;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 67, 126, 1);
    }
  }
}
