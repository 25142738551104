// Spinner
.ds_spinner {
  position: absolute;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #3c3b3b;
  opacity: 0.6;
  z-index: 2;
  img {
    width: 40px;
  }
}

.ds_loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #6c6b6b;
  opacity: 0.6;
  z-index: 2;
  img {
    width: 40px;
  }
}
