// Notifications Components
.notifications {
    margin-bottom: 25px;
}

.notifications_list {
    padding: 10px 20px 10px 20px;
    border-left: 5px solid $ds_colour__link--hover__background;
    // Unread Notification Style
    .notification_unread{
        font-weight: bold;
        h4{
            font-weight: bold;
        }
    }

    // Read Notification Style
    .notification_read{
        font-weight: 400;
        h4{
            font-weight: 400;
        }
    }

    .notification_title {
        color: $ds_colour__brand;
        padding-bottom: 5px;
    }

    .notification_item_list {
        color: $ds_colour__text--secondary;
         ul{
             padding: 0;
             margin-left: 0;
             li{
                 padding: 0;
                 list-style: none;
                 font-size: 19px;
                 margin-bottom: 5px;
             }
         }
    }
    &:hover{
        background-color: $ds_colour__link--hover__background;
        border-left: 5px solid $ds_colour__brand--secondary;
        cursor: pointer;
    }
}