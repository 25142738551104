.notifsidebar__wrapper {
  position: absolute;
  height: 83vh;
  width: 35%;
  margin-top: 0px;
  top: 58px;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  border-left: 2px solid $ds_colour__brand;
  overflow-y: scroll;
  z-index: 10;

  .notifications {
    &:first-child {
      margin-top: 0px;
    }
    margin-bottom: 5px;
    h5 {
      margin-bottom: 0px;
    }
  }
}
