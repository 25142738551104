.contextual_navbar__wrapper {
  position: absolute;
  height: 83vh;
  width: 35%;
  margin-top: 0px;
  top: 58px;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  border-left: 2px solid $ds_colour__brand;
  overflow-y: scroll;
  z-index: 10;

  .context_nav {
    &:first-child {
      margin-top: 0px;
    }
    margin-bottom: 5px;
    h5 {
      margin-bottom: 0px;
    }
    p {
      color: #333;
      font-weight: 700;
      padding: 1rem 1rem 0.5rem 1rem;
      width: 100%;
      display: inline-block;
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin-bottom: 0;
    }
    a {
      display: inline-block;
      text-decoration: none;
      padding: 1rem 1rem;
      margin-bottom: 0;
      width: 100%;
    }

    .ds_current {
      color: #333;
      font-weight: 500;
    }
  }
}
